import { Playground } from 'docz';
import { H1, H2, H3, H4, H5, H6, Subtitle1, Subtitle2, Body1, Body2 } from "../../../../../builder-ui/app/src/ui/typography";
import * as React from 'react';
export default {
  Playground,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  React
};
/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  max-width: ${props => (props.width ? `${props.width}px` : 'auto')};
  margin: 0 auto;
  > * {
    margin: 10px;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Holder = styled.div`
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  margin: 4px;
`

const IconHolder = styled(Holder)`
  padding: 8px;
  width: 160px;
  font-size: 14px;
  > div {
    margin-left: 8px;
  }
`

export const IconGrid = ({ Icons }) => {
  return (
    <Grid>
      {map(Icons, (Icon, key) => (
        <IconHolder>
          <Icon />
          <div>{key}</div>
        </IconHolder>
      ))}
    </Grid>
  )
}

const IllustrationHolder = styled(Holder)`
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  > div {
    margin-top: 8px;
  }
`

export const IllustrationList = ({ Illustrations }) => {
  return (
    <Grid>
      {map(Illustrations, (Illustration, key) => (
        <IllustrationHolder>
          <Illustration />
          <div>{key}</div>
        </IllustrationHolder>
      ))}
    </Grid>
  )
}
